<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppLink, { LinkColor } from "@/Components/Shared/link/AppLink.vue";
import { useAttrs } from "vue";
import { MenuItemVariant } from "@/Components/Shared/menu/AppMenuItem.vue";

type Props = {
    color?: LinkColor;
    variant?: MenuItemVariant;
    href: string;
};

const { color = "current", variant = undefined } = defineProps<Props>();
const attrs = useAttrs();
const classes: AntlerClasses<Props> = {
    base: "grow px-2.5 py-1.5 w-full hover:bg-gray-50 text-slate-500 text-left hover:no-underline",
    variants: {
        color: {
            primary: "text-primary",
            currentColor: "text-current",
            default: "text-black",
            white: "text-white hover:bg-primaryDark",
            grayLight: "text-slate-300",
        },
        variant: {
            tab: "px-4 py-2 rounded-t overflow-hidden hover:text-black",
            activeTab: "bg-slate-100 px-4 py-2 rounded-t hover:bg-slate-200",
            main: "",
            mainActive:
                "rounded bg-gradient-to-br from-slate-200 to-slate-50 text-slate-600 hover:bg-slate-300",
        },
    },
};
const { aClass } = installAntlerComponent(
    "menu__link",
    { color, variant },
    classes,
    attrs,
);
</script>

<template>
    <AppLink
        :class="aClass()"
        :href="href"
        :color="color"
    >
        <slot />
    </AppLink>
</template>
