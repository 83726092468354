<script setup lang="ts">
import { ref } from "vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { onClickOutside } from "@vueuse/core";
import AppMenuList from "@/Components/Shared/menu/AppMenuList.vue";
import AppMenuBurger from "@/Components/Shared/menu/AppMenuBurger.vue";

export type MenuVariant = "inline" | "main" | "tabs";

type Props = {
    withBurger?: boolean;
    variant?: MenuVariant;
};

const { withBurger = false, variant = undefined } = defineProps<Props>();

const target = ref(null);
const classes: AntlerClasses<Props> = {
    base: "flex flex-col",
    variants: {
        variant: {
            inline: "",
            main: "m-2 overflow-auto min-h-1 grow",
            tabs: "flex-row border-b border-slate-200 overflow-x-auto overflow-y-hidden px-4",
        },
    },
};
const { aClass } = installAntlerComponent("menu", { variant }, classes);

const menuActive = ref(false);

// methods
const activateMenu = () => {
    menuActive.value = !menuActive.value;
};

onClickOutside(target, () => {
    if (withBurger) {
        menuActive.value = false;
    }
});
</script>

<template>
    <nav
        ref="target"
        :class="aClass()"
    >
        <AppMenuList
            :variant="variant"
            :active="menuActive"
        >
            <slot />
        </AppMenuList>

        <AppMenuBurger
            v-if="withBurger"
            v-model="menuActive"
            class="md:hidden"
            @click="activateMenu"
        />
    </nav>
</template>
