<script setup lang="ts">
import { useAttrs } from "vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { LinkColor } from "@/Components/Shared/link/AppLink.vue";
import AppMenuLink from "@/Components/Shared/menu/AppMenuLink.vue";
const emit = defineEmits(["click"]);

export type MenuItemVariant = "tab" | "activeTab" | "main" | "mainActive";

// setup
type Props = {
    href?: string;
    suffix?: string | number;
    color?: LinkColor;
    variant?: MenuItemVariant;
};
const {
    href = undefined,
    suffix = undefined,
    color = "currentColor",
    variant = undefined,
} = defineProps<Props>();
const attrs = useAttrs();

// classes
const classes: AntlerClasses<Props> = {
    base: "inline-flex flex-col items-center font-medium",
    variants: {
        color: {
            primary: "text-primary",
            currentColor: "text-current",
            default: "text-black",
            white: "text-white",
            grayLight: "text-slate-300",
            red: "text-red-500",
        },
        variant: {
            tab: "w-auto grow-0 text-slate-600",
            activeTab: "text-black",
            main: "rounded hover:bg-slate-200",
            mainActive: "",
        },
    },
};
const { aClass } = installAntlerComponent(
    "menu__item",
    { color, variant },
    classes,
    attrs,
);
</script>

<template>
    <li :class="aClass()">
        <AppMenuLink
            v-if="href"
            :href="href"
            :suffix="suffix"
            :color="color"
            :variant="variant"
        >
            <slot />
        </AppMenuLink>
        <slot v-else />
        <slot name="after" />
    </li>
</template>
