<script setup lang="ts">
import { useAttrs } from "vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { MenuVariant } from "@/Components/Shared/menu/AppMenu.vue";

type Props = {
    variant?: MenuVariant;
    active?: boolean;
};
const { variant = undefined, active = false } = defineProps<Props>();
const attrs = useAttrs();

const classes: AntlerClasses<Props> = {
    base: "flex flex-col grow w-full gap-1",
    variants: {
        variant: {
            inline: "flex-row items-center",
            main: "text-base gap-2",
            tabs: "-mb-px flex-row",
        },
    },
};
const { aClass } = installAntlerComponent(
    "menu__list",
    { variant },
    classes,
    attrs,
);
</script>

<template>
    <ul
        :class="
            aClass({
                'lt-md:flex': active,
            })
        "
    >
        <slot />
    </ul>
</template>
