<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppButton from "@/Components/Shared/button/AppButton.vue";

// setup
type Props = {
    modelValue:
        | boolean
        | number
        | string
        | Array<any>
        | undefined
        | Record<string, any>;
};
const { modelValue = undefined } = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);

// classes
const classes: AntlerClasses<Props> = {
    base: "p-1 sm:px-2",
};
const { aClass } = installAntlerComponent("hamburger", {}, classes);
</script>

<template>
    <AppButton
        :class="aClass()"
        size="regular"
        icon-size="small"
        :icon="modelValue ? 'cross' : 'menu'"
        variant="ghost"
        @click="$emit('update:modelValue', !modelValue)"
    />
</template>
